import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { useAuthentication } from "./utils/hooks/useAuthentication";

import Root, {
  loader as rootLoader,
  action as rootAction,
} from "./routes/root";
import ErrorPage from "./error-page";
import Index from "./routes/index";
import Contact, {
  loader as contactLoader,
  action as contactAction,
} from "./routes/contact";
import EditContact, { action as editAction } from "./routes/edit";
import { action as destroyAction } from "./routes/destroy";

// Custom routes.
import Register from "./routes/register";
import Login from "./routes/login";
import Logout, { loader as logoutLoader } from "./routes/logout";
import User from "./routes/user";
import Home, { loader as homeLoader } from "./routes/home";
import Subscribe, { loader as subscribeLoader } from "./routes/subscribe";
import PreProfile, { loader as preProfileLoader } from "./routes/preprofile";
import Payment, { loader as paymentLoader } from "./routes/payment";
import Payments, { loader as paymentsLoader } from "./routes/payments";
import Profile, { loader as profileLoader } from "./routes/profile";

import TNC from "./routes/tnc";
import Services from "./routes/services";

import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

const router = createBrowserRouter([
  // Root route.
  {
    path: "/",
    element: <Register />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "logout",
    element: <Logout />,
    loader: logoutLoader,
  },

  // User root route.
  {
    path: "user",
    element: <User />,
    errorElement: <ErrorPage />,

    children: [
      { index: true, element: <Home />, loader: homeLoader },
      {
        path: "services",
        element: <Services />,
        // loader: tncLoader,
        // action: contactAction,
      },
      {
        path: "tnc",
        element: <TNC />,
        // loader: tncLoader,
        // action: contactAction,
      },
      {
        path: "subscribe",
        element: <Subscribe />,
        loader: subscribeLoader,
        // action: contactAction,
      },
      {
        path: "subscribe/profile",
        element: <PreProfile />,
        loader: preProfileLoader,
        // action: contactAction,
      },
      {
        path: "subscribe/payment",
        element: <Payment />,
        loader: paymentLoader,
        // action: contactAction,
      },
      {
        path: "payments",
        element: <Payments />,
        loader: paymentsLoader,
        // action: contactAction,
      },
      {
        path: "profile",
        element: <Profile />,
        loader: profileLoader,
        // action: contactAction,
      },
    ],
  },

  // Contacts root route.
  {
    path: "contacts",
    element: <Root />,
    errorElement: <ErrorPage />,
    loader: rootLoader, // Provide data to the route element before it renders.
    action: rootAction, // Perform data mutations.

    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <Index /> },
          {
            path: "contacts/:contactId",
            element: <Contact />,
            loader: contactLoader,
            action: contactAction,
          },
          /* the rest of the routes */
        ],
      },
    ],

    /* children: [
      { index: true, element: <Index /> },
      {
        path: "contacts/:contactId",
        element: <Contact />,
        loader: contactLoader,
        action: contactAction,
      },
      {
        path: "contacts/:contactId/edit",
        element: <EditContact />,
        loader: contactLoader,
        action: editAction,
      },
      {
        path: "contacts/:contactId/destroy",
        action: destroyAction,
        errorElement: <div>Oops! There was an error.</div>,
      },
    ], */
  }, // END: Root route.
]);

function App() {
  // const { user } = useAuthentication();
  // console.log("fb user:", user);

  return <RouterProvider router={router} />;
}

export default App;
