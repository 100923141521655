import { useEffect, useState, useRef } from "react";
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  redirect,
  useNavigation,
  useNavigate,
  useSubmit,
} from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Select from "react-dropdown-select";
import ReactModal from "react-modal";

import AirConServiceImage from "../assets/images/service-aircon-02.jpg";
import ElectricalServiceImage from "../assets/images/service-electrical-02.jpg";
import PlumbingServiceImage from "../assets/images/service-plumbing-02.jpg";

export default function Services() {
  const renderServices = () => {
    return (
      <div class="container">
            <div class="section-title text-start">
                <h1 class="display-5 mb-5">Our Services</h1>
            </div>
            <div class="row g-4 d-flex">
                <div class="col-md-6 col-lg-4 wow fadeInUp d-flex" data-wow-delay="0.1s">
                    <div class="service-item d-flex flex-column">
                        <div class="overflow-hidden">
                            <img class="img-fluid" src={AirConServiceImage} alt="" />
                        </div>
                        <div class="p-4 border border-5 border-light border-top-0 flex-grow-1">
                            <h4 class="mb-3">Air Conditioner Works</h4>
                            <ul class="list1">
                                <li>Basic servicing (4 months once)</li>
                                <li>Gas checking / refilling</li>
                                <li>
                                    Chemical wash (Exclusively for sign-up members only)&nbsp;
                                    <span class="text-red">($80 per unit)</span>
                                    &nbsp;
                                    <span class="badge rounded-pill bg-danger text-uppercase">Special Price</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 wow fadeInUp d-flex" data-wow-delay="0.3s">
                    <div class="service-item d-flex flex-column">
                        <div class="overflow-hidden">
                            <img class="img-fluid" src={ElectricalServiceImage} alt="" />
                        </div>
                        <div class="p-4 border border-5 border-light border-top-0 flex-grow-1">
                            <h4 class="mb-3">Electrical Works</h4>
                            <ul class="list1">
                                <li>Checking of circuit failure</li>
                                <li>Replacement of faulty accessories</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 wow fadeInUp d-flex" data-wow-delay="0.5s">
                    <div class="service-item d-flex flex-column">
                        <div class="overflow-hidden">
                            <img class="img-fluid" src={PlumbingServiceImage} alt="" />
                        </div>
                        <div class="p-4 border border-5 border-light border-top-0 flex-grow-1">
                            <h4 class="mb-3">Plumbing Works</h4>
                            <ul class="list1">
                                <li>Checking of water leakage</li>
                                <li>Changing of water tap</li>
                                <li>Changing of instant heater set</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  };

  const renderFAQs = () => {
    return (
      <div class="container py-5">
            <div class="section-title text-start">
                <h1 class="display-5 mb-5">FAQs</h1>
            </div>
            <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                        What services are not provided?
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                    <div class="accordion-body">
                        <h6 class="">Air Conditioner Works</h6>
                        <ul class="list1">
                            <li>No new installation works</li>
                            <li>No replacement of fancoil / compressor</li>
                        </ul>

                        <h6 class="mt-4">Electrical Works</h6>
                        <ul class="list1">
                            <li>No wiring works</li>
                            <li>No relocation works</li>
                            <li>No hacking / plastering works</li>
                        </ul>

                        <h6 class="mt-4">Plumbing Works</h6>
                        <ul class="list1">
                            <li>No replacement of storage heater and mixer</li>
                            <li>No installation / replacement of WC / Wash basin / Sink</li>
                            <li>No additional installation for new water point</li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                        I'm not comfortable with subscribing to a recurring plan. Can I terminate at any time?
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                    <div class="accordion-body">
                        <p>Certainly! You have the flexibility to terminate your subscription at any time.</p>
                        <p>
                          Please refer to our&nbsp;
                          <a href="/user/tnc">terms and conditions</a>
                          {/* <NavLink to={`/user/tnc`}>terms and conditions</NavLink> */}
                          &nbsp;for detailed information regarding subscription termination and related policies.
                        </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
  };

  return <div id="services">
    {renderServices()}
    {renderFAQs()}
  </div>;
}
