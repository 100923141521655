// Reference: https://formik.org/docs/guides/validation
export const validateEmail = (value) => {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
};

export const isEmail = (value) => {
  const error = validateEmail(value);
  if (error) {
    return false;
  }
  return true;
};
