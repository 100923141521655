// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/* const firebaseConfig = {
  apiKey: "AIzaSyA-R4FN3MrSyyNutEup7mq7topkkuUef_A",
  authDomain: "d-one-home-services.firebaseapp.com",
  projectId: "d-one-home-services",
  storageBucket: "d-one-home-services.appspot.com",
  messagingSenderId: "109777497785",
  appId: "1:109777497785:web:36b8ed048a3b58c1d17568",
  measurementId: "G-862HB5YN2T",
}; */

// D HOME - TESTING.
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/* const firebaseConfig = {
  apiKey: "AIzaSyATZzh1dRx8L7iR12pwWfEM56qYqWfwuuw",
  authDomain: "d-home-solutions.firebaseapp.com",
  projectId: "d-home-solutions",
  storageBucket: "d-home-solutions.appspot.com",
  messagingSenderId: "288435987514",
  appId: "1:288435987514:web:695ccd6a6393b12aba4103",
  measurementId: "G-S4H2ZG8E1R",
}; */

// D HOME - PRODUCTION.
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAhnccKFw9-eoaEw1FeIzITnLUeHLdvTW4",
  authDomain: "d-home--production.firebaseapp.com",
  projectId: "d-home--production",
  storageBucket: "d-home--production.appspot.com",
  messagingSenderId: "739697437101",
  appId: "1:739697437101:web:e814a20f260c3327e09924",
  measurementId: "G-X1MP97Q9SC",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const analytics = getAnalytics(app);

/* export function onAuthStateChange() {
  return auth.onAuthStateChanged((user) => {
    if (user) {
      console.log("The user is logged in");
    } else {
      console.log("The user is not logged in");
    }
  });
} */
