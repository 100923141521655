import { useEffect, useState } from "react";
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  redirect,
  useNavigation,
  useNavigate,
  useSubmit,
} from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Select from "react-dropdown-select";
import ReactModal from "react-modal";

import { getProducts } from "../services/ProductsService";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export async function loader() {
  const products = await getProducts();
  return { products };
}

export default function Subscribe() {
  const { products } = useLoaderData();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.houseType) {
      errors.houseType = "Required";
    }

    return errors;
  };

  const onSubmit = async (data) => {
    console.log("onSubmit", data);

    try {
      localStorage.setItem("subscribing", JSON.stringify(data));
      // redirect(`/user/profile`);
      navigate("/user/subscribe/profile");
    } catch (error) {
      console.log("ERROR in onSubmit:", error);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div id="subscribe" className="container">
      <div class="section-title text-start">
        <h1 class="display-5 mb-5">Subscribe</h1>
      </div>

      <div className="form-container">
        <Formik
          initialValues={{
            houseType: products[0],
          }}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form id="subscribe-form">
              <div className="form-row">
                <label htmlFor="houseType">House Type</label>
                <Select
                  options={products}
                  values={[values.houseType]}
                  multi={false}
                  searchable={false}
                  create={false}
                  onChange={(values2) => {
                    // handleChange("houseType");
                    setFieldValue("houseType", values2[0]);
                  }}
                />
              </div>

              {values.houseType && (
                <div className="form-row2">
                  <p className="has-icon">
                    You have selected&nbsp;
                    <span className="text-bold">{values.houseType.label}</span>:
                  </p>
                  <ul className="list1">
                    <li>
                      <div style={{ display: "flex" }}>
                        First Payment: $
                        {values.houseType.first_payment?.toFixed(2)}
                        &nbsp;
                        <i
                          className="icon icon-clickable text-red"
                          onClick={openModal}
                        >
                          <InfoOutlinedIcon />
                        </i>
                      </div>
                    </li>
                    <li>
                      Monthly payment (after initial 12 months): $
                      {values.houseType.recurring_payment?.toFixed(2)}
                    </li>
                  </ul>
                </div>
              )}

              <div className="form-row">
                <button type="submit">Next</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <ReactModal
        isOpen={isModalOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        className="modal1 modal1-sm"
        overlayClassName="overlay"
      >
        <button className="modal1-close" onClick={closeModal}>
          <CloseOutlinedIcon />
        </button>

        <div className="modal1-content">
          <header className="modal1-header">Payment Terms</header>

          <p className="mt-3">As a first-time subscriber:</p>
          <ul className="list1">
            <li>
              You enjoy the exclusive offer of 12 months of home maintenance
              services while paying only for 10 months.
            </li>
            <li>
              After the initial 12 months, you will be enrolled in a convenient
              monthly billing cycle to ensure an uninterrupted experience of our
              exceptional services.
            </li>
          </ul>

          {/* <p>
            <Link to={`/user/services`}>
              Find out more about our services here.
            </Link>
          </p> */}
        </div>
      </ReactModal>
    </div>
  );
}
