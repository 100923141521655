// Firebase.
import { app } from "../config/firebase";
import { getFirestore, doc, getDoc } from "firebase/firestore";

import { APPCONFIG } from "../config/constants";

export const getProducts = async () => {
  try {
    const url = `${APPCONFIG.api_url}/get-products.php`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.log("ERROR in getProducts:", error);
    return [];
  }
};
