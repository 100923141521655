// Firebase.
import { app } from "../config/firebase";
import {
  getFirestore,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  setDoc,
  collection,
  query,
  where,
  or,
  orderBy,
  limit,
} from "firebase/firestore";

import { APPCONFIG } from "../config/constants";

// Reference: https://stackoverflow.com/a/10727155/21965721
export const generateRandomAlphabets = (length) => {
  const mask = "ABCDEFGHJKLMNPQRSTUVWXYZ";
  let result = "";
  for (var i = length; i > 0; --i) {
    result += mask[Math.floor(Math.random() * mask.length)];
  }
  return result;
};

export const getNewCustomerId = async () => {
  let customerId = 1;

  try {
    const db = getFirestore(app);
    const usersRef = collection(db, "users");
    const queryRef = query(usersRef, orderBy("created_time", "desc"), limit(1));

    const querySnapshot = await getDocs(queryRef);
    // console.log(
    //   querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    // );
    if (querySnapshot.empty === false) {
      let customerIdTemp = querySnapshot.docs[0].data().customerId;
      customerIdTemp = customerIdTemp.slice(6);
      customerId = parseInt(customerIdTemp) + 1;
    }

    const alphabets = generateRandomAlphabets(5);
    const paddedCustomerId = customerId.toString().padStart(6, "0");

    return alphabets + "-" + paddedCustomerId;
  } catch (error) {
    console.log("getLastCustomerId error:", error);
  }
};

export const getUser = async (uid) => {
  const db = getFirestore(app);
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);
  return docSnap;
};

export const updateUser = async (uid, data) => {
  const db = getFirestore(app);
  const docRef = doc(db, "users", uid);

  updateDoc(docRef, {
    email: data.email,
    fullName: data.fullName,
    phoneNum: data.phoneNum,
    address: data.address,
    postalCode: data.postalCode,
  });
};

export const updateUser2 = async (uid, newFields) => {
  const db = getFirestore(app);
  setDoc(doc(db, "users", uid), newFields, { merge: true });
};

export const loginUserPhone = async (phoneNum) => {
  const db = getFirestore(app);
  const usersRef = collection(db, "users");
  const queryRef = query(usersRef, where("phoneNum", "==", phoneNum));
  const querySnapshot = await getDocs(queryRef);
  return querySnapshot;
};
