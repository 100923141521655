import { useEffect, useState } from "react";
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  redirect,
  useNavigation,
  useSubmit,
} from "react-router-dom";
import { Formik, Form, Field } from "formik";
import ReactModal from "react-modal";

// Stripe.
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";

import { APPCONFIG } from "../config/constants";
import { useAuthentication } from "../utils/hooks/useAuthentication";
import { fetchPaymentSheetParams } from "../services/PaymentsService";
import { updateUser2 } from "../services/UsersService";

import PaymentSheet from "../routes/payment-sheet";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Hidden } from "@mui/material";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(APPCONFIG.stripe.publishableKey);

export async function loader() {
  const subscribing = localStorage.getItem("subscribing");
  const subscribingData = JSON.parse(subscribing);

  const localUser = localStorage.getItem("localUser");
  const localUserData = JSON.parse(localUser);

  return { subscribingData, localUserData };
}

export default function Payment() {
  const { user } = useAuthentication();
  const { subscribingData, localUserData } = useLoaderData();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [stripeOptions, setStripeOptions] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.tnc) {
      errors.tnc = "Required";
    }

    return errors;
  };

  const onSubmit = async () => {
    console.log("onSubmit", subscribingData, localUserData);
    setIsPaymentLoading(true);

    await initializePaymentSheet();
    // await openPaymentSheet();
  };

  const initializePaymentSheet = async () => {
    const { clientSecret, ephemeralKey, customer } =
      await fetchPaymentSheetParams(
        subscribingData?.houseType?.value,
        localUserData,
      );

    setStripeOptions({
      clientSecret: clientSecret,
      // Reference: https://stripe.com/docs/elements/appearance-api?platform=web#rules
      appearance: {
        theme: "stripe",
        labels: "floating",
        rules: {
          ".Error": {
            fontSize: "var(--fontSizeXs)",
          },
        },
      },
    });

    openPaymentModal();

    updateUser2(user.uid, {
      stripeCustomerId: customer,
    });
    localUserData.stripeCustomerId = customer;
    localStorage.setItem("localUser", JSON.stringify(localUserData));

    /* const { error } = await initPaymentSheet({
      merchantDisplayName: APPCONFIG.company.name,
      customerId: customer,
      customerEphemeralKeySecret: ephemeralKey,
      paymentIntentClientSecret: clientSecret,
      // setupIntentClientSecret: clientSecret,
      // Set `allowsDelayedPaymentMethods` to true if your business can handle payment
      // methods that complete payment after a delay, like SEPA Debit and Sofort.
      allowsDelayedPaymentMethods: false,
      // billingDetailsCollectionConfiguration: {
      //   email: "always",
      // },
      defaultBillingDetails: {
        email: subscribingData.user.email,
        name: subscribingData.user.fullName,
        address: {
          country: "SG",
          line1: subscribingData.user.address,
          postalCode: subscribingData.user.postalCode,
        },
      },
    });
    if (!error) {
      setLoading(true);
    } */
  };

  const openInfoModal = () => {
    setIsInfoModalOpen(true);
  };

  const closeInfoModal = () => {
    setIsInfoModalOpen(false);
  };

  const openPaymentModal = () => {
    setIsPaymentModalOpen(true);
  };

  const closePaymentModal = () => {
    setIsPaymentModalOpen(false);
    setIsPaymentLoading(false);
  };

  return (
    <div id="payment" className="container">
      <div class="section-title text-start">
        <h1 class="display-5 mb-5">Confirm</h1>
      </div>

      <div className="form-container">
        <Formik
          initialValues={{
            tnc: false,
          }}
          enableReinitialize
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values }) => (
            <Form id="confirm-payment-form">
              <div className="form-row2">
                <p className="has-icon">
                  You have selected&nbsp;
                  <span className="text-bold">
                    {subscribingData.houseType.label}
                  </span>
                  :
                </p>
                <ul className="list1">
                  <li>
                    <div style={{ display: "flex" }}>
                      First Payment: $
                      {subscribingData.houseType.first_payment?.toFixed(2)}
                      &nbsp;
                      <i
                        className="icon icon-clickable text-red"
                        onClick={openInfoModal}
                      >
                        <InfoOutlinedIcon />
                      </i>
                    </div>
                  </li>
                  <li>
                    Monthly payment (after initial 12 months): $
                    {subscribingData.houseType.recurring_payment?.toFixed(2)}
                  </li>
                </ul>

                <div className="form-row">
                  <label className={`check-label ${errors.tnc ? "error" : ""}`}>
                    <Field type="checkbox" id="tnc" name="tnc" />
                    By subscribing to our services, you are indicating your
                    agreement to our&nbsp;
                    <a
                      href="https://d-homesolutions.com/tnc"
                      target="_blank"
                      rel="noreferrer"
                      style={{ lineHeight: 1.1 }}
                    >
                      terms and conditions
                    </a>
                    .
                  </label>
                </div>
              </div>

              <div className="form-row">
                <button type="submit" disabled={isPaymentLoading}>
                  Subscribe
                </button>
                {isPaymentLoading && <small className="mt-2">Loading...</small>}
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <ReactModal
        isOpen={isInfoModalOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeInfoModal}
        className="modal1 modal1-sm"
        overlayClassName="overlay"
      >
        <button className="modal1-close" onClick={closeInfoModal}>
          <CloseOutlinedIcon />
        </button>

        <div className="modal1-content">
          <header className="modal1-header">Payment Terms</header>

          <p className="mt-3">As a first-time subscriber:</p>
          <ul className="list1">
            <li>
              You enjoy the exclusive offer of 12 months of home maintenance
              services while paying only for 10 months.
            </li>
            <li>
              After the initial 12 months, you will be enrolled in a convenient
              monthly billing cycle to ensure an uninterrupted experience of our
              exceptional services.
            </li>
          </ul>

          {/* <p>
            <Link to={`/user/services`}>
              Find out more about our services here.
            </Link>
          </p> */}
        </div>
      </ReactModal>

      <ReactModal
        isOpen={isPaymentModalOpen}
        shouldCloseOnOverlayClick={false}
        className="modal1 modal1-sm modal-payment"
        overlayClassName="overlay"
      >
        <button className="modal1-close" onClick={closePaymentModal}>
          <CloseOutlinedIcon />
        </button>

        <div className="modal1-content">
          {stripeOptions && (
            <Elements stripe={stripePromise} options={stripeOptions}>
              <PaymentSheet
                user={user}
                subscribingData={subscribingData}
                localUserData={localUserData}
              />
            </Elements>
          )}

          <small className="powered-by">
            Powered by&nbsp;
            <a href="https://stripe.com/en-sg" target="_blank" rel="noreferrer">
              Stripe.com
            </a>
          </small>
        </div>
      </ReactModal>
    </div>
  );
}
