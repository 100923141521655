import { useEffect, useState } from "react";
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  redirect,
  useNavigation,
  useNavigate,
  useSubmit,
} from "react-router-dom";

// Helpers/Services.
import { APPCONFIG } from "../config/constants";
import { getUserInvoices } from "../services/PaymentsService";
import { renderContactUsInfo } from "../services/ContactUsService";

export async function loader({ request }) {
  const url = new URL(request.url);
  let r = url.searchParams.get("r");
  if (r === "1") {
    // return redirect(`/user/payments?r=0`);
    window.location.href = "/user/payments";
    return {};
  }

  const localUser = localStorage.getItem("localUser");
  const localUserData = JSON.parse(localUser);

  const invoices = await getUserInvoices(localUserData.email);
  return { invoices, localUserData };
}

export default function Payments() {
  const { invoices, localUserData } = useLoaderData();
  const navigate = useNavigate();

  const renderInvoices = () => {
    return invoices.map((item, index) => {
      return (
        <li key={index}>
          <a href={item.url} target="_blank" rel="noreferrer">
            <span className="left">{item.label}</span>
            <span className="right">${item.amount_paid.toFixed(2)}</span>
          </a>
        </li>
      );
    });
  };

  return (
    <div id="invoices" className="container">
      {!invoices && <span>Retrieving...</span>}

      {invoices && (
        <div className="invoices-container">
          <h4 className="mb-4">Payments</h4>
          <ul className="invoices-list">{renderInvoices()}</ul>
        </div>
      )}
    </div>
  );
}
