import { useEffect, useState } from "react";
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  redirect,
  useNavigation,
  useNavigate,
  useSubmit,
} from "react-router-dom";
import { Formik, Form, Field } from "formik";

// Firebase.
import { app, auth } from "../config/firebase";
import { getFirestore, getDocs } from "firebase/firestore";
import { collection, query, where } from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
} from "firebase/auth";

import { useAuthentication } from "../utils/hooks/useAuthentication";
import { getUser, updateUser } from "../services/UsersService";
import { isEmail } from "../utils/EmailHelper";

export async function loader({ request }) {
  return null;
}

export default function PreProfile() {
  const navigate = useNavigate();
  const { user } = useAuthentication();
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (!user) return;

    getUser(user.uid)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setCurrentUser(data);
        }
      })
      // make sure to catch any error
      .catch((error) => {
        console.log(error);
      });
  }, [user]);

  const validate = (values) => {
    const errors = {};

    if (!values.email || !isEmail(values.email)) {
      errors.email = "Required";
    }

    if (!values.phoneNum || values.phoneNum.length !== 8) {
      errors.phoneNum = "Required";
    }

    if (!values.fullName || values.fullName.length < 4) {
      errors.fullName = "Required";
    }

    if (!values.address) {
      errors.address = "Required";
    }

    if (!values.postalCode || values.postalCode.length !== 6) {
      errors.postalCode = "Required";
    }

    return errors;
  };

  const onSubmit = async (data) => {
    console.log("onSubmit", data);

    try {
      updateUser(user.uid, data);

      const updatedUser = {
        ...currentUser,
        ...data,
      };
      setCurrentUser(updatedUser);
      localStorage.setItem("localUser", JSON.stringify(updatedUser));

      navigate("/user/subscribe/payment");
    } catch (error) {
      console.log("ERROR in onSubmit:", error);
    }
  };

  return (
    <div id="profile" className="container">
      <h6 className="mb-3">Please ensure your particulars are correct:</h6>

      <div className="form-container">
        <Formik
          initialValues={{
            email: currentUser?.email ?? "",
            phoneNum: currentUser?.phoneNum ?? "",
            fullName: currentUser?.fullName ?? "",
            address: currentUser?.address ?? "",
            postalCode: currentUser?.postalCode ?? "",
          }}
          enableReinitialize
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form id="profile-form">
              <div className="form-row">
                <label htmlFor="email">Email</label>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  placeholder="yourname@mail.com"
                  className={`${errors.email ? "error" : ""}`}
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                />
              </div>

              <div className="form-row">
                <label htmlFor="phoneNum">Contact No.</label>
                <Field
                  type="text"
                  id="phoneNum"
                  name="phoneNum"
                  placeholder="91234567"
                  className={`${errors.phoneNum ? "error" : ""}`}
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                />
              </div>

              <div className="form-row">
                <label htmlFor="fullName">Full Name in NRIC</label>
                <Field
                  type="text"
                  id="fullName"
                  name="fullName"
                  placeholder="@yourname"
                  className={`${errors.fullName ? "error" : ""}`}
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                />
              </div>

              <div className="form-row">
                <label htmlFor="address">
                  Address for Home Maintenance Services
                </label>
                <Field
                  type="text"
                  id="address"
                  name="address"
                  placeholder="123 Home Avenue #01-234"
                  className={`${errors.address ? "error" : ""}`}
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                />
              </div>

              <div className="form-row">
                <label htmlFor="postalCode">Postal Code</label>
                <Field
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  placeholder="123456"
                  className={`${errors.postalCode ? "error" : ""}`}
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                />
              </div>

              <div className="form-row">
                <button type="submit">Next</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
