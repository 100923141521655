export const APPCONFIG = {
  company: {
    name: "D HOME SOLUTIONS PTE. LTD.",
    phone: "6980 8880",
    contactEmail: "contact@d-homesolutions.com",
  },

  // Stripe configurations.
  stripe: {
    // publishableKey:
    // "pk_live_51NOzy9BM4KgMLXuBf3hjlnJ7augicZPetGiVCXXdjD3yClm2dFEj7T0lZe1iLgDpnl1nZE44hxMksp2d27ADIGlT00fenEzwCo",
    // "pk_test_51NOzy9BM4KgMLXuBno8lDSypKY95698JdLPBZSTj6MCxJHgB8kmEekyPNui0zuAUpiuikrY0T6iI6lMaHHmDcZ9y00uDaEMzLo",

    // PROD.
    publishableKey:
      "pk_live_51NZSHSEOFYjfmmMJVtFZsOsTPewXFFc4KPxigNjTmcFNdr44RnqSqxoZ0rRZJXJ3KLi7wtD1rJ5XDnZFlK5lYgUh00zJTVaMpW",
    // publishableKey:
    //   "pk_test_51NZSHSEOFYjfmmMJSji7XT3rfY6numZ1u3n66fevhzLKTql7CLtOHmcXq9QuWEOzIhRZGZxqrQaUGOu52I1xPTEG00lzFMJeA3",
  },

  // API URL.
  // api_url: process.env.EXPO_PUBLIC_DHOME_API_URL,
  api_url: "https://d-homesolutions.com/mobile/api",
  // api_url:
  //   "http://localhost/utrix-2023/home-services/06-D-Home-Customer-React/api",

  // Services URL.
  services_url: "https://d-homesolutions.com/mobile/services/services.html",
};
