// Firebase.
import { app } from "../config/firebase";
import { getFirestore, doc, getDoc } from "firebase/firestore";

export const getHomeImages = async () => {
  const db = getFirestore(app);
  const docRef = doc(db, "home", "home_images");
  const docSnap = await getDoc(docRef);
  return docSnap;
};
