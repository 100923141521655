import { useEffect, useState } from "react";
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  redirect,
  useNavigation,
  useNavigate,
  useSubmit,
} from "react-router-dom";
import { Formik, Form, Field } from "formik";

// Firebase.
import { app, auth } from "../config/firebase";
import { getFirestore, getDocs, doc } from "firebase/firestore";
import { collection, query, where } from "firebase/firestore";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";

// Toast.
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuthentication } from "../utils/hooks/useAuthentication";
import { getUser, updateUser } from "../services/UsersService";
import { isEmail } from "../utils/EmailHelper";

const toastId = "toast1";

export async function loader({ request }) {
  return null;
}

export default function Profile() {
  const navigate = useNavigate();
  const { user } = useAuthentication();
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (!user) return;

    getUser(user.uid)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setCurrentUser(data);
        }
      })
      // make sure to catch any error
      .catch((error) => {
        console.log(error);
      });
  }, [user]);

  const validate = (values) => {
    const errors = {};

    if (!values.email || !isEmail(values.email)) {
      errors.email = "Required";
    }

    if (!values.phoneNum || values.phoneNum.length !== 8) {
      errors.phoneNum = "Required";
    }

    if (!values.fullName || values.fullName.length < 4) {
      errors.fullName = "Required";
    }

    if (!values.address) {
      errors.address = "Required";
    }

    if (!values.postalCode || values.postalCode.length !== 6) {
      errors.postalCode = "Required";
    }

    return errors;
  };

  const onSubmit = async (data) => {
    console.log("onSubmit", data);

    try {
      updateUser(user.uid, data);

      const updatedUser = {
        ...currentUser,
        ...data,
      };
      setCurrentUser(updatedUser);
      localStorage.setItem("localUser", JSON.stringify(updatedUser));

      toast.success("Updated!", {
        toastId: toastId,
        position: "bottom-center",
        draggable: false,
      });
    } catch (error) {
      console.log("ERROR in onSubmit:", error);
    }
  };

  const validate2 = (values) => {
    const errors = {};

    if (!values.password || values.password.length < 6) {
      errors.password = "Required";
    }

    if (!values.newPassword || values.newPassword.length < 6) {
      errors.newPassword = "Required";
    }

    return errors;
  };

  const reauthenticate = (currentPassword) => {
    var cred = EmailAuthProvider.credential(user.email, currentPassword);
    return reauthenticateWithCredential(user, cred);
  };

  const onSubmit2 = async (data, { resetForm }) => {
    // console.log("onSubmit2", data);

    try {
      reauthenticate(data.password)
        .then(() => {
          updatePassword(user, data.newPassword)
            .then(() => {
              toast.success("Updated!", {
                toastId: toastId,
                position: "bottom-center",
                draggable: false,
              });

              resetForm();
            })
            .catch((error) => {
              console.log("ERROR in updatePassword:", error);
              toast.error("Please try again later.", {
                toastId: toastId,
                position: "bottom-center",
                draggable: false,
              });
            });
        })
        .catch((error) => {
          console.log("ERROR in reauthenticate:", error.code, error.message);

          if (error.code.indexOf("auth/wrong-password") !== -1) {
            toast.error("Your password is incorrect.", {
              toastId: toastId,
              position: "bottom-center",
              draggable: false,
            });
          } else {
            toast.error("Please try again later.", {
              toastId: toastId,
              position: "bottom-center",
              draggable: false,
            });
          }
        });
    } catch (error) {
      console.log("ERROR in onSubmit2:", error);
      toast.error("Please try again later.", {
        toastId: toastId,
        position: "bottom-center",
        draggable: false,
      });
    }
  };

  return (
    <div id="profile" className="container">
      <div class="section-title text-start">
        <h1 class="display-5 mb-5">Profile</h1>
      </div>

      <div className="form-container">
        <Formik
          initialValues={{
            email: currentUser?.email ?? "",
            phoneNum: currentUser?.phoneNum ?? "",
            fullName: currentUser?.fullName ?? "",
            address: currentUser?.address ?? "",
            postalCode: currentUser?.postalCode ?? "",
          }}
          enableReinitialize
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form id="profile-form">
              <div className="form-row">
                <label htmlFor="email">Email</label>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  placeholder="yourname@mail.com"
                  className={`${errors.email ? "error" : ""}`}
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                />
              </div>

              <div className="form-row">
                <label htmlFor="phoneNum">Contact No.</label>
                <Field
                  type="text"
                  id="phoneNum"
                  name="phoneNum"
                  placeholder="91234567"
                  className={`${errors.phoneNum ? "error" : ""}`}
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                />
              </div>

              <div className="form-row">
                <label htmlFor="fullName">Full Name in NRIC</label>
                <Field
                  type="text"
                  id="fullName"
                  name="fullName"
                  placeholder="@yourname"
                  className={`${errors.fullName ? "error" : ""}`}
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                />
              </div>

              <div className="form-row">
                <label htmlFor="address">
                  Address for Home Maintenance Services
                </label>
                <Field
                  type="text"
                  id="address"
                  name="address"
                  placeholder="123 Home Avenue #01-234"
                  className={`${errors.address ? "error" : ""}`}
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                />
              </div>

              <div className="form-row">
                <label htmlFor="postalCode">Postal Code</label>
                <Field
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  placeholder="123456"
                  className={`${errors.postalCode ? "error" : ""}`}
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                />
              </div>

              <div className="form-row">
                <button type="submit">Update</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div className="form-container mt-5 mb-4">
        <hr />
        <div class="section-title text-start mt-5">
          <h1 class="display-5 mb-5">Change Password</h1>
        </div>

        <Formik
          initialValues={{
            password: "",
            newPassword: "",
          }}
          enableReinitialize
          validate={validate2}
          onSubmit={onSubmit2}
        >
          {({ errors, touched }) => (
            <Form id="profile-form">
              <div className="form-row">
                <label htmlFor="password">Current Password</label>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  className={`${errors.password ? "error" : ""}`}
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                />
              </div>

              <div className="form-row">
                <label htmlFor="newPassword">New Password</label>
                <Field
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  placeholder="Password"
                  className={`${errors.newPassword ? "error" : ""}`}
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                />
              </div>

              <div className="form-row">
                <button type="submit">Update</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <ToastContainer />
    </div>
  );
}
