// Reference: https://firebase.google.com/docs/auth/web/password-auth

import { redirect } from "react-router-dom";

// Firebase.
import { auth } from "../config/firebase";
import { signOut } from "firebase/auth";

export async function loader() {
  await signOut(auth);

  // Clean up.
  localStorage.setItem("localUser", JSON.stringify({})); // Empty.
  localStorage.removeItem("subscribing");

  return redirect(`/login`);
}

export default function Logout() {
  return <div>Logging out..</div>;
}
