import { useEffect } from "react";
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  Form,
  redirect,
  useNavigation,
  useSubmit,
} from "react-router-dom";

// Helpers/Services.
import { getHomeImages } from "../services/HomeService";
import { renderContactUsInfo } from "../services/ContactUsService";

export async function loader({ request }) {
  const url = new URL(request.url);
  let r = url.searchParams.get("r");
  if (r === "1") {
    // Event snippet for (Google) Sign Up Website - D Home conversion page.
    window.gtag("event", "conversion", {
      send_to: "AW-11334336815/TgprCMK_oe0YEK-C0Zwq",
    });

    setTimeout(() => {
      // return redirect(`/user/payments?r=0`);
      window.location.href = "/user/";
    }, 1000);

    return {};
  }

  const localUser = localStorage.getItem("localUser");
  const localUserData = JSON.parse(localUser);

  if (localUserData?.subscription_plan) {
    return { localUserData };
  }

  const docSnap = await getHomeImages();
  if (docSnap.exists()) {
    const data = docSnap.data();
    if (data.images) {
      return { localUserData, homeImages: data.images };
    }
  }

  return { localUserData };
}

export default function Home() {
  const { localUserData, homeImages } = useLoaderData();
  const navigation = useNavigation();

  const renderHomeImages = () => {
    return homeImages.map((image, index) => {
      return (
        <img
          key={index}
          src={image.url}
          alt=""
          width={image.width}
          height={image.height}
        />
      );
    });
  };

  return (
    <div id="home" className="container">
      {!localUserData?.subscription_plan && homeImages && (
        <div className="home-images-container">
          <div class="section-title text-start">
            <h1 class="display-5 mb-5">Promotion</h1>
          </div>

          <div className="home-images">{renderHomeImages()}</div>
        </div>
      )}

      {localUserData?.subscription_plan && renderContactUsInfo(localUserData)}
    </div>
  );
}
