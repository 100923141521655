import { useEffect, useState, useRef } from "react";
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  redirect,
  useNavigation,
  useNavigate,
  useSubmit,
} from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Select from "react-dropdown-select";
import ReactModal from "react-modal";

import { getProducts } from "../services/ProductsService";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export async function loader() {}

export default function TNC() {
  const renderTNC = () => {
    return (
      <div class="container">
            <div class="section-title text-start">
                <h1 class="display-5 mb-5">Terms & Conditions</h1>
            </div>
            <div class="section-content text-start">
                <h4 class="mb-3">1. Subscription Plan Description</h4>
                <p>1.1 The service offered by D HOME SOLUTIONS PTE. LTD. ("the Company"), a Singapore-based entity with its registered office address at 3 TOA PAYOH INDUSTRIAL PARK, #01-1355, TOA PAYOH INDUSTRIAL PARK, SINGAPORE 319055 with UEN 202316610R, is based on a recurring monthly subscription plan. By subscribing to the services provided by the Company ("the Service"), you agree to pay the applicable subscription fees in accordance with the terms outlined in this agreement.</p>
                <p>1.2 The subscription plan details, including the subscription term, recurring payment amount, and billing frequency, will be clearly specified at the time of purchase or sign up.</p>

                <h4 class="mb-3 mt-5">2. Recurring Billing</h4>
                <p>2.1 Upon subscribing to the Service, you authorize the Company to charge your chosen payment method for the recurring subscription fees as per the selected subscription plan.</p>
                <p>2.2 The subscription fees will be automatically charged at the beginning of each billing cycle, as determined by the selected subscription plan at the time of purchase or sign up.</p>

                <h4 class="mb-3 mt-5">3. Payment Authorization</h4>
                <p>3.1 By providing your payment information and agreeing to these terms and conditions, you authorize the Company to charge your chosen payment method for the subscription fees and any additional charges related to the Service.</p>
                <p>3.2 Payment for the subscription fees must be made strictly via the subscription-based payment method authorized by the Company. No cash or cheque payments will be accepted onsite for the Service.</p>
                <p>3.3 It is your responsibility to ensure that the payment information provided is accurate and up-to-date. Failure to maintain valid payment information may result in the suspension or termination of the Service.</p>

                <h4 class="mb-3 mt-5">4. Billing Notifications</h4>
                <p>4.1 By subscribing to the Service, you hereby consent to receive email notifications from our payment provider, <a href="https://stripe.com/en-sg" target="_blank">Stripe</a>, as part of the subscription process. These email notifications may include, but are not limited to, receipts, failed payment attempts, payment confirmations, and important updates related to your subscription.</p>
                <p>4.2 The Company will utilize Stripe's email notification system to keep you informed of essential transactional information related to your subscription. It is essential to keep your email address up to date to ensure the accurate and timely delivery of these notifications.</p>
                <p>4.3 Please note that while we strive to provide accurate and reliable email notifications, the Company shall not be held responsible for any issues arising from the delivery or non-receipt of such notifications due to factors beyond our control, including, but not limited to, technical difficulties, spam filters, or incorrect email addresses provided by the subscriber.</p>
                <p>4.4 By subscribing to our services, you acknowledge and agree to receive email notifications from Stripe as described above. For more information on Stripe's email communication policies, you may refer to their privacy policy and terms of service available on their website.</p>

                <h4 class="mb-3 mt-5">5. Continuous Recurring Payments and Account Termination</h4>
                <p>5.1 Recurring payments must be continuous to ensure the uninterrupted provision of the Service.</p>
                <p>5.2 Any lapse in payment will result in the termination of your subscription and access to the Service.</p>
                <p>5.3 If your account is terminated due to non-payment, and you wish to reinstate your account, you will be required to pay all outstanding fees, including arrears for the period of non-payment.</p>

                <h4 class="mb-3 mt-5">6. Cancellation and Refunds</h4>
                <p>6.1 You have the right to cancel your subscription at any time. To cancel the subscription and prevent further recurring payments, you must follow the cancellation procedure specified by the Company.</p>
                <p>6.2 Please note that cancellation requests must be received before the next billing cycle to avoid being charged for the subsequent period.</p>
                <p>6.3 The Company does not offer refunds for partially used subscription periods. Upon cancellation, your subscription will remain active until the end of the current billing cycle.</p>
                <p>6.4 To cancel your subscription, please visit the following link: <a href="https://billing.stripe.com/p/login/00g3gb4Xq2IlbPqfYY" style={{ overflowWrap: "break-word" }}>https://billing.stripe.com/p/login/00g3gb4Xq2IlbPqfYY</a></p>

                <h4 class="mb-3 mt-5">7. Changes to Subscription Plan</h4>
                <p>7.1 The Company reserves the right to modify the subscription plans, including pricing and billing frequency, with prior notice to you.</p>
                <p>7.2 If you do not agree with the changes to the subscription plan, you have the right to cancel your subscription as outlined in Section 6.</p>

                <h4 class="mb-3 mt-5">8. The Service</h4>
                <p>8.1 Materials required for the services are to be supplied by the owner. The Company will not provide materials for the maintenance works.</p>
                <p>8.2 Once the maintenance works are confirmed, no additional works beyond the scope agreed upon will be performed on-site.</p>
                <p>8.3 For urgent maintenance requests such as circuit failure or water leakage, please contact us immediately at <a href="tel:+6569808880">6980 8880</a> to expedite the response. We strive to attend to urgent cases within 2 hours.</p>
                <p>8.4 For cases that require replacements of items, kindly contact us at <a href="tel:+6569808880">6980 8880</a> at least 2 days in advance to schedule an appointment. Please ensure that materials required for replacement are prepared and ready the day before the scheduled appointment.</p>

                <h4 class="mb-3 mt-5">9. Modifications to the Service</h4>
                <p>9.1 The Company reserves the right to modify, suspend, or discontinue any part of the Service without prior notice. We will make reasonable efforts to notify you of any significant changes that may impact your subscription.</p>
            </div>
        </div>
    );
  };

  return <div id="tnc">{renderTNC()}</div>;
}
