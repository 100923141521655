import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";

// Stripe.
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

import { updateUser2 } from "../services/UsersService";

export default function PaymentSheet({ user, subscribingData, localUserData }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // return_url: "http://localhost:3000/user/subscribe/payment",
        return_url:
          "https://customer.d-homesolutions.com/user/subscribe/payment",
      },
      redirect: "if_required",
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      console.log("payment success");

      const newFields = {
        subscription_status: "new_subscriber",
        subscription_plan: subscribingData.houseType,
      };
      updateUser2(user.uid, newFields);

      localStorage.removeItem("subscribing");

      localUserData.subscription_status = "new_subscriber";
      localUserData.subscription_plan = subscribingData.houseType;
      localStorage.setItem("localUser", JSON.stringify(localUserData));

      navigate("/user/?r=1", { replace: true });
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement />

      <div className="form-row">
        <button id="btn-pay" disabled={!stripe}>
          Pay ${subscribingData.houseType.first_payment?.toFixed(2)}
        </button>
      </div>

      {/* Show error message to your customers */}
      {errorMessage && <div className="errorMessage">{errorMessage}</div>}
    </form>
  );
}
