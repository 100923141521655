// Reference: https://firebase.google.com/docs/auth/web/start

import React, { useEffect, useState } from "react";

// Firebase.
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { auth } from "../../config/firebase";

/*
By calling onAuthStateChanged, we subscribe to an event that triggers every time the authorization state changes, such as when a user logs in or logs out of the application.
*/
export function useAuthentication() {
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    const unsubscribeFromAuthStatuChanged = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        // const uid = user.uid;
        setUser(user);
      } else {
        // User is signed out
        setUser(undefined);
      }
    });

    return unsubscribeFromAuthStatuChanged;
  }, []);

  return {
    user,
  };
}
