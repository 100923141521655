import React, { useEffect, useState } from "react";
import { useNavigate, Navigate, NavLink } from "react-router-dom";
import { Formik, Form, Field } from "formik";

// Firebase.
import { app, auth } from "../config/firebase";
import { getFirestore, getDocs } from "firebase/firestore";
import { collection, query, where } from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
} from "firebase/auth";

// Toast.
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuthentication } from "../utils/hooks/useAuthentication";
import { getUser, loginUserPhone } from "../services/UsersService";

// Custom Helpers.
import { isEmail } from "../utils/EmailHelper";

// Custom UI.
import { ReactComponent as Logo } from "../assets/images/logo1.svg";

const toastId = "toast1";

export default function Login() {
  const { user } = useAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      getUser(user.uid)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            localStorage.setItem("localUser", JSON.stringify(data));

            navigate("/user/", { replace: true });
          }
        })
        // make sure to catch any error
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user]);

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    }

    if (!values.password) {
      errors.password = "Required";
    }

    return errors;
  };

  const onSubmit = async (data) => {
    // console.log("onSubmit", data);

    let sanitisedEmail = data.email.toLowerCase();

    if (!isEmail(sanitisedEmail)) {
      try {
        const querySnapshot = await loginUserPhone(data.email);

        if (querySnapshot.empty === false) {
          // Contact Num exists.
          const email = querySnapshot.docs[0].data().email;
          sanitisedEmail = email;
        } else {
          // Contact Num does not exist.
          toast.error("Your login credentials are incorrect.", {
            toastId: toastId,
            position: "bottom-center",
            draggable: false,
          });
        }
      } catch (error) {
        console.log("onSubmit error 1", error.code, error.message);
      }
    }

    if (isEmail(sanitisedEmail)) {
      try {
        await signInWithEmailAndPassword(auth, sanitisedEmail, data.password);
        // navigate("/user/", { replace: true });
      } catch (error) {
        console.log("onSubmit error 2", error.code, error.message);

        if (
          error.code.indexOf("auth/invalid-email") !== -1 ||
          error.code.indexOf("auth/wrong-password") !== -1
        ) {
          toast.error("Your login credentials are incorrect.", {
            toastId: toastId,
            position: "bottom-center",
            draggable: false,
          });
        } else if (error.code.indexOf("auth/user-not-found") !== -1) {
          toast.error("You are not registered.", {
            toastId: toastId,
            position: "bottom-center",
            draggable: false,
          });
        } else {
          toast.error("Please try again later.", {
            toastId: toastId,
            position: "bottom-center",
            draggable: false,
          });
        }
      }
    } else {
      // TODO: Sign in with Phone Number.
      // await signInWithPhoneNumber(auth, data.email, data.password);
    }
  };

  return (
    <div id="login">
      <div className="inner">
        <header>
          <Logo />
          <h3>Welcome Back!</h3>
        </header>

        <div className="divider"></div>

        <div className="form-container mt-3">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validate={validate}
            onSubmit={onSubmit}
          >
            {({ errors, touched }) => (
              <Form id="login-form">
                <div className="form-row">
                  <label htmlFor="email">Email / Mobile</label>
                  <Field
                    type="text"
                    id="email"
                    name="email"
                    placeholder="yourname@mail.com"
                    className={`${errors.email ? "error" : ""}`}
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                  />
                </div>

                <div className="form-row">
                  <label htmlFor="password">Password</label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    className={`${errors.password ? "error" : ""}`}
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                  />
                </div>

                <div className="form-row">
                  <button type="submit">Sign In</button>

                  <br />
                  <p style={{ textAlign: "center" }}>
                    Don't have an account?{" "}
                    <NavLink to={`/`}>Sign Up Now.</NavLink>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}
