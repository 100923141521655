// Firebase.
import { app } from "../config/firebase";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";

import { APPCONFIG } from "../config/constants";

export const fetchPaymentSheetParams = async (houseTypeValue, userData) => {
  const url = `${APPCONFIG.api_url}/receive-first-payment.php`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      type: houseTypeValue,
      name: userData.fullName,
      email: userData.email,
      phone: userData.phoneNum,
      address: userData.address,
      postalCode: userData.postalCode,
      customerId: userData.customerId,
    }),
  });

  const { clientSecret, ephemeralKey, customer } = await response.json();

  return {
    clientSecret,
    ephemeralKey,
    customer,
  };
};

export const getUserInvoices = async (email) => {
  try {
    const url = `${APPCONFIG.api_url}/get-user-invoices.php`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.log("ERROR in getProducts:", error);
    return [];
  }
};
