import { APPCONFIG } from "../config/constants";

export const renderContactUsInfo = (user) => {
  let text1 = "To make any inquiries, please contact us:";
  if (user?.subscription_plan) {
    text1 =
      "To schedule an appointment, make inquiries, or provide feedback, please contact us:";
  }

  return (
    <div id="contact-banner" class="alert alert-primary" role="alert">
      <h4 class="alert-heading mb-1">Customer Support</h4>
      <h6 class="mb-4">(Mon - Sun: 8 AM - 10 PM)</h6>
      <p>{text1}</p>
      <p class="mb-3">
        <i class="fa fa-phone me-3"></i>
        <a href={`tel:+65` + APPCONFIG.company.phone.replaceAll(" ", "")}>
          {APPCONFIG.company.phone}
        </a>
      </p>
      <p class="mb-3">
        <i class="icon icon-whatsapp-alt me-3"></i>
        <a href="https://wa.me/6583168281" target="_blank">
          Chat on WhatsApp
        </a>
      </p>
      <p class="mb-3">
        <i class="fa fa-envelope me-3"></i>
        <a href={`mailto:` + APPCONFIG.company.contactEmail}>
          {APPCONFIG.company.contactEmail}
        </a>
      </p>

      {user?.subscription_plan && (
        <div className="pt-2">
          <hr />
          <p className="pt-2">
            When reaching out to us, kindly quote your customer ID to help us
            assist you better.
          </p>

          <p className="fw-bold">Customer ID: {user.customerId}</p>
        </div>
      )}
    </div>
  );
};
