import { useEffect, useState } from "react";
import {
  Outlet,
  NavLink,
  Link,
  useLoaderData,
  Form,
  redirect,
  useNavigation,
  useNavigate,
  useSubmit,
} from "react-router-dom";

import { useAuthentication } from "../utils/hooks/useAuthentication";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";

export default function User() {
  const { user } = useAuthentication();
  const navigation = useNavigation();
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState(null);

  /* useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  }, []); */

  // TODO: Temp. solution.
  useEffect(() => {
    try {
      const localUser = localStorage.getItem("localUser");
      const localUserData = JSON.parse(localUser);

      if (Object.keys(localUserData).length === 0) {
        navigate("/login", { replace: true });
      } else {
        setCurrentUser(localUserData);
      }
    } catch (error) {
      console.log("ERROR in user:", error.code, error.message);
      navigate("/login", { replace: true });
    }
  }, []);

  return (
    <>
      <div id="header">
        <nav
          class="navbar bg-dark navbar-expand-md bg-body-tertiary fixed-top"
          data-bs-theme="dark"
          role="navigation"
        >
          <div class="container">
            <a class="navbar-brand">D Home Solutions</a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-md-0">
                <li class="nav-item">
                  <NavLink
                    to={`/user/tnc`}
                    className={({ isActive, isPending }) =>
                      isActive
                        ? "nav-link active"
                        : isPending
                        ? "nav-link pending"
                        : "nav-link"
                    }
                  >
                    Terms & Conditions
                  </NavLink>
                </li>
              </ul>

              <ul className="navbar-nav">
                <li class="nav-item">
                  <NavLink
                    to={`/logout`}
                    className={({ isActive, isPending }) =>
                      isActive
                        ? "nav-link active"
                        : isPending
                        ? "nav-link pending"
                        : "nav-link"
                    }
                  >
                    Sign Out
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <div
        id="detail"
        className={navigation.state === "loading" ? "loading" : ""}
      >
        <Outlet />
      </div>

      <div id="header-bottom">
        <nav class="navbar navbar-light fixed-bottom" role="navigation">
          <div class="container">
            <ul class="navbar-nav d-flex flex-row justify-content-around w-100">
              <li class="nav-item">
                <NavLink
                  to={`/user/`}
                  className={({ isActive, isPending }) =>
                    isActive
                      ? "nav-link active"
                      : isPending
                      ? "nav-link pending"
                      : "nav-link"
                  }
                >
                  <div className="row d-flex flex-column justify-content-center align-items-center">
                    <i className="icon bottom-tab-icon">
                      <HomeOutlinedIcon />
                    </i>
                    <div className="bottom-tab-label">Home</div>
                  </div>
                </NavLink>
              </li>
              {currentUser?.subscription_plan && (
                <li class="nav-item">
                  <NavLink
                    to={`/user/profile`}
                    className={({ isActive, isPending }) =>
                      isActive
                        ? "nav-link active"
                        : isPending
                        ? "nav-link pending"
                        : "nav-link"
                    }
                  >
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                      <i className="icon bottom-tab-icon">
                        <Person2OutlinedIcon />
                      </i>
                      <div className="bottom-tab-label">Profile</div>
                    </div>
                  </NavLink>
                </li>
              )}
              {currentUser?.subscription_plan && (
                <li class="nav-item">
                  <NavLink
                    to={`/user/payments`}
                    className={({ isActive, isPending }) =>
                      isActive
                        ? "nav-link active"
                        : isPending
                        ? "nav-link pending"
                        : "nav-link"
                    }
                  >
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                      <i className="icon bottom-tab-icon">
                        <AttachMoneyOutlinedIcon />
                      </i>
                      <div className="bottom-tab-label">Payments</div>
                    </div>
                  </NavLink>
                </li>
              )}
              <li class="nav-item">
                <NavLink
                  to={`/user/services`}
                  className={({ isActive, isPending }) =>
                    isActive
                      ? "nav-link active"
                      : isPending
                      ? "nav-link pending"
                      : "nav-link"
                  }
                >
                  <div className="row d-flex flex-column justify-content-center align-items-center">
                    <i className="icon bottom-tab-icon">
                      <GridViewOutlinedIcon />
                    </i>
                    <div className="bottom-tab-label">Services</div>
                  </div>
                </NavLink>
              </li>
              {!currentUser?.subscription_plan && (
                <li class="nav-item">
                  <NavLink
                    to={`/user/subscribe`}
                    className={({ isActive, isPending }) =>
                      isActive
                        ? "nav-link active"
                        : isPending
                        ? "nav-link pending"
                        : "nav-link"
                    }
                  >
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                      <i className="icon bottom-tab-icon">
                        <ShoppingCartOutlinedIcon />
                      </i>
                      <div className="bottom-tab-label">Subscribe</div>
                    </div>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}
