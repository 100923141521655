import React, { useEffect, useState } from "react";
import { useNavigate, Navigate, NavLink } from "react-router-dom";
import { Formik, Form, Field } from "formik";

// Firebase.
import { app, auth } from "../config/firebase";
import {
  getFirestore,
  getDocs,
  setDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { collection, query, where, or } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";

// Toast.
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuthentication } from "../utils/hooks/useAuthentication";
import { getUser, getNewCustomerId } from "../services/UsersService";

// Custom Helpers.
import { isEmail } from "../utils/EmailHelper";

// Custom UI.
import { ReactComponent as Logo } from "../assets/images/logo1.svg";

const toastId = "toast1";

export default function Register() {
  const { user } = useAuthentication();
  const navigate = useNavigate();
  const [newUser, setNewUser] = useState(null);

  useEffect(() => {
    if (user) {
      getUser(user.uid)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            localStorage.setItem("localUser", JSON.stringify(data));

            navigate("/user/", { replace: true });
          } else {
            getNewCustomerId()
              .then((newCustomerId) => {
                const db = getFirestore(app);
                console.log("newUser", newUser);
                setDoc(
                  doc(db, "users", user.uid),
                  {
                    customerId: newCustomerId,
                    email: newUser?.email.toLowerCase(),
                    fullName: newUser?.fullName,
                    phoneNum: newUser?.phoneNum,
                    created_time: serverTimestamp(),
                  },
                  { merge: true }
                );

                navigate("/user/", { replace: true });
              })
              // make sure to catch any error
              .catch((error) => {
                console.log("ERROR in getNewCustomerId:", error);
              });
          }
        })
        // make sure to catch any error
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user, newUser]);

  const validate = (values) => {
    const errors = {};

    if (!values.email || !isEmail(values.email)) {
      errors.email = "Required";
    }

    if (!values.phoneNum || values.phoneNum.length !== 8) {
      errors.phoneNum = "Required";
    }

    if (!values.password || values.password.length < 6) {
      errors.password = "Required";
    }

    if (!values.fullName || values.fullName.length < 4) {
      errors.fullName = "Required";
    }

    return errors;
  };

  const onSubmit = async (data) => {
    console.log("onSubmit", data);

    // Reference: https://cloud.google.com/firestore/docs/query-data/queries#web-version-9_1
    // Reference: https://firebase.google.com/docs/reference/js/v8/firebase.firestore.QuerySnapshot
    try {
      const db = getFirestore(app);
      const usersRef = collection(db, "users");
      // const queryRef = query(usersRef, where("phoneNum", "==", data.phoneNum));
      const queryRef = query(
        usersRef,
        or(
          where("phoneNum", "==", data.phoneNum),
          where("email", "==", data.email.toLowerCase())
        )
      );

      const querySnapshot = await getDocs(queryRef);
      if (querySnapshot.empty === false) {
        // Contact Num or Email is in use.
        toast.error("You are already registered.", {
          toastId: toastId,
          position: "bottom-center",
          draggable: false,
        });
        return;
      }

      setNewUser(data);

      await createUserWithEmailAndPassword(
        auth,
        data.email.toLowerCase(),
        data.password
      );
      // navigate("/user/", { replace: true });
    } catch (error) {
      console.log("ERROR in onSubmit:", error);
      toast.error("Please try again later.", {
        toastId: toastId,
        position: "bottom-center",
        draggable: false,
      });
    }
  };

  return (
    <div id="register">
      <div className="inner">
        <header>
          <Logo />
          <h3>Register NOW!</h3>
        </header>

        <div className="divider"></div>

        <div className="form-container mt-3">
          <Formik
            initialValues={{
              email: "",
              password: "",
              fullName: "",
              phoneNum: "",
            }}
            validate={validate}
            onSubmit={onSubmit}
          >
            {({ errors, touched }) => (
              <Form id="register-form">
                <div className="form-row">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="text"
                    id="email"
                    name="email"
                    placeholder="yourname@mail.com"
                    className={`${errors.email ? "error" : ""}`}
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                  />
                </div>

                <div className="form-row">
                  <label htmlFor="password">Password</label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    className={`${errors.password ? "error" : ""}`}
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                  />
                </div>

                <div className="form-row">
                  <label htmlFor="phoneNum">Contact Number</label>
                  <Field
                    type="text"
                    id="phoneNum"
                    name="phoneNum"
                    placeholder="xxxxxxxx"
                    className={`${errors.phoneNum ? "error" : ""}`}
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                  />
                </div>

                <div className="form-row">
                  <label htmlFor="fullName">Full Name in NRIC</label>
                  <Field
                    type="text"
                    id="fullName"
                    name="fullName"
                    placeholder="@yourname"
                    className={`${errors.fullName ? "error" : ""}`}
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                  />
                </div>

                <div className="form-row">
                  <button type="submit">Sign Up</button>

                  <br />
                  <p style={{ textAlign: "center" }}>
                    Have an account? <NavLink to={`/login`}>Sign In</NavLink>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}
